import { WorkflowType } from '@kindo/universal';
import { Chat } from '~/components/Chat';
enum WorkstationHistoryDateGroup {
  LAST_2_WEEKS = 'lastTwoWeeks',
  LAST_5_DAYS = 'lastFiveDays',
  LAST_MONTH = 'lastMonth',
  OLDER = 'older',
}
export const WORKSTATION_HISTORY_DATE_GROUPS: Array<{
  key: WorkstationHistoryDateGroup;
  title: string;
}> = [{
  key: WorkstationHistoryDateGroup.LAST_5_DAYS,
  title: 'LAST 5 DAYS'
}, {
  key: WorkstationHistoryDateGroup.LAST_2_WEEKS,
  title: 'LAST 2 WEEKS'
}, {
  key: WorkstationHistoryDateGroup.LAST_MONTH,
  title: 'LAST MONTH'
}, {
  key: WorkstationHistoryDateGroup.OLDER,
  title: 'OLDER'
}];
type GroupedItems<T> = { [key in (typeof WORKSTATION_HISTORY_DATE_GROUPS)[number]['key']]: T[] };
export const groupWorkstationHistoryItemsByDate = <T extends {
  timestamp: string;
},>(items: T[]): GroupedItems<T> => {
  const now = new Date();
  const fiveDaysAgo = new Date(now.getTime() - 5 * 24 * 60 * 60 * 1000);
  const twoWeeksAgo = new Date(now.getTime() - 14 * 24 * 60 * 60 * 1000);
  const oneMonthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
  return {
    lastFiveDays: items.filter(item => new Date(item.timestamp) >= fiveDaysAgo),
    lastTwoWeeks: items.filter(item => {
      const date = new Date(item.timestamp);
      return date >= twoWeeksAgo && date < fiveDaysAgo;
    }),
    lastMonth: items.filter(item => {
      const date = new Date(item.timestamp);
      return date >= oneMonthAgo && date < twoWeeksAgo;
    }),
    older: items.filter(item => new Date(item.timestamp) < oneMonthAgo)
  };
};
export function getWorkflowRunTypeOfChat(chat: Chat): WorkflowType {
  // eslint-disable-next-line no-underscore-dangle
  const stepCount = chat.workflow?._count?.steps ?? 0;
  if (chat.workflowTriggerId) {
    return WorkflowType.TRIGGERED;
  }
  if (stepCount === 0) {
    return WorkflowType.CHATBOT;
  }
  return WorkflowType.MANUAL;
}