import { CHAT_ERROR_TITLES, ChatErrorCode, ChatMessageContentType, ChatMessageRole, ChatMessageState, getIntegrationFromMergeIntegrationSlug, getKindoErrorMessage, getUserInputPromptTemplateTag, isChatErrorCode, isKindoErrorCode, isModelAccessErrorCode, MODEL_ACCESS_ERROR_TITLES, toTool } from '@kindo/universal';
import { BaseDisplayedChatMessage, ChatMessageItem, DisplayedChatMessage, ReadyUserChatMessage, ResponseChatMessage, UserChatMessage } from './Chat.types';
import { BadgeColor } from '~/components/core/Badge/Badge.consts';
import { ContentSource, isLlmWorkflowStep, isPromptTemplateWorkflowStepInput, PromptTemplateWorkflowStepInput, WorkflowStep } from '~/types';
const getContentFileName = (contentRef: ChatMessageItem['chatMessageContent'][0]): string => contentRef?.content?.file?.filename || contentRef?.content?.externalCache?.filename || 'Unknown Filename';
const getContentMimeType = (contentRef: ChatMessageItem['chatMessageContent'][0]): string => contentRef?.content?.file?.contentType || contentRef?.content?.externalCache?.mimeType || 'Unknown MIME type';
const getContentSourceFromContent = (content: ChatMessageItem['chatMessageContent'][number]['content']): ContentSource => {
  const integration = getIntegrationFromMergeIntegrationSlug(content?.externalCache?.mergeAccount.slug ?? null);
  return integration ?? 'Library';
};

/**
 * Transforms a ChatMessage returned from the API into a DisplayedChatMessage
 * that can be used in the frontend.
 */
export const chatItemToDisplayedChatMessage = (chatItem: ChatMessageItem): DisplayedChatMessage => {
  const baseDisplayedChatMessage: BaseDisplayedChatMessage = {
    id: chatItem.id,
    message: chatItem.message,
    modelIdentifier: chatItem.model,
    modelDisplayName: chatItem.modelDisplayName ?? null,
    // TODO: Fix casting, type guard inside API
    role: chatItem.role as ChatMessageRole,
    // TODO: Fix casting, type guard inside API
    state: chatItem.state as ChatMessageState,
    batch: chatItem.batch ? {
      batchId: chatItem.batch.id,
      prompt: chatItem.batch.prompt
    } : undefined,
    referencedContent: chatItem.chatMessageContent?.map(contentRef => ({
      fileName: getContentFileName(contentRef),
      // Needs to be contentId and not id,
      // required to download image previews in UserChatMessage
      contentId: contentRef.contentId || '',
      creatorId: contentRef.content.file?.creatorId || contentRef.content.externalCache?.mergeAccount.integration.user.id || '',
      referenceId: contentRef.id,
      mimeType: getContentMimeType(contentRef),
      source: getContentSourceFromContent(contentRef.content),
      sourceContent: contentRef.sourceContent || undefined,
      type: contentRef.type as ChatMessageContentType
    })),
    artifacts: chatItem.chatMessageWebResult?.map(artifact => ({
      url: artifact.url,
      pageTitle: artifact.pageTitle,
      sourceContent: artifact.sourceContent,
      source: 'Web',
      contentId: artifact.id,
      tool: toTool(artifact.tool)
    })),
    workflowStepNumber: chatItem.workflowStepNumber ?? undefined,
    transformedMessage: chatItem.transformedMessage ?? undefined,
    workflowInputValues: chatItem.workflowInputValues
  };

  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (true) {
    // Response message
    // Note: Using baseDisplayedChatMessage.role instead of chatItem.role for typing, since
    // chatItem.role is currently typed as a string
    case baseDisplayedChatMessage.role === ChatMessageRole.ASSISTANT:
      return {
        ...baseDisplayedChatMessage,
        codeExecution: chatItem.chatMessageCodeExecution,
        role: baseDisplayedChatMessage.role,
        isStreaming: false
      } satisfies ResponseChatMessage;
    // Ready user message
    case baseDisplayedChatMessage.role === ChatMessageRole.USER && baseDisplayedChatMessage.state === ChatMessageState.READY:
      return {
        ...baseDisplayedChatMessage,
        displayName: chatItem.workflowStep?.displayName ?? undefined,
        role: baseDisplayedChatMessage.role,
        state: baseDisplayedChatMessage.state,
        userInputs: chatItem.workflowStep ? getSortedPromptTemplateInputs(chatItem.workflowStep) : []
      } satisfies ReadyUserChatMessage;

    // User message
    case baseDisplayedChatMessage.role === ChatMessageRole.USER:
      return {
        ...baseDisplayedChatMessage,
        role: baseDisplayedChatMessage.role,
        displayName: chatItem.workflowStep?.displayName ?? undefined
      } satisfies UserChatMessage;
    // System message
    case baseDisplayedChatMessage.role === ChatMessageRole.SYSTEM:
    case baseDisplayedChatMessage.role === ChatMessageRole.DEVELOPER:
    default:
      return {
        ...baseDisplayedChatMessage,
        role: baseDisplayedChatMessage.role
      } satisfies DisplayedChatMessage;
  }
};

/**
 * Returns the workflow step inputs sorted by their position in the prompt template.
 */
function getSortedPromptTemplateInputs(workflowStep: WorkflowStep): PromptTemplateWorkflowStepInput[] {
  // Currently only LLM workflow steps display user inputs
  // in chat UI
  if (!isLlmWorkflowStep(workflowStep)) {
    return [];
  }

  // Filter the inputs to get only PromptTemplateWorkflowStepInputs
  const promptTemplateInputs = workflowStep.inputs.filter(isPromptTemplateWorkflowStepInput) ?? [];

  // Sort by the position of the tag in the prompt template
  const inputsWithPosition = promptTemplateInputs.sort((a, b) => {
    const tagA = getUserInputPromptTemplateTag(a.templateResolutionName);
    const tagB = getUserInputPromptTemplateTag(b.templateResolutionName);
    return workflowStep.promptTemplate.indexOf(tagA) - workflowStep.promptTemplate.indexOf(tagB);
  });
  return inputsWithPosition;
}
export const getMessageErrorInfo = (message: string, state: ChatMessageState) => {
  const isError = state === ChatMessageState.ERROR;
  const isKindoError = isError && isKindoErrorCode(message);
  const isHandledKindoError = isKindoError && message !== ChatErrorCode.CHAT_UNEXPECTED_ERROR;
  return {
    isError,
    isKindoError,
    isHandledKindoError,
    displayedMessage: isKindoError ? getKindoErrorMessage(message) : message
  };
};
export const getBadgeColorForMessage = (isError: boolean, isHandledKindoError: boolean) => {
  if (isHandledKindoError) {
    return BadgeColor.WARNING;
  }
  if (isError) {
    return BadgeColor.ERROR;
  }
  return BadgeColor.PRIMARY_BRIGHT;
};
export const getErrorMessageText = (isHandledKindoError: boolean, displayedMessage: string) => isHandledKindoError ? displayedMessage : 'Please try again, and contact support if it continues.';
export const getErrorMessageTitle = (isHandledKindoError: boolean, originalMessage?: string) => {
  if (isHandledKindoError) {
    if (originalMessage && isChatErrorCode(originalMessage)) {
      return CHAT_ERROR_TITLES[originalMessage];
    }
    if (originalMessage && isModelAccessErrorCode(originalMessage)) {
      return MODEL_ACCESS_ERROR_TITLES[originalMessage];
    }
    return 'Warning';
  }
  return 'Something Went Wrong';
};