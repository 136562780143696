import { ChatMessageItem } from '~/components/Chat/Chat.types';
interface EnteredFile {
  id: string;
  name: string;
}
export const getEnteredFilesFromMessages = (data: {
  items: ChatMessageItem[];
}): EnteredFile[] => data.items
// Get only user messages with content
.filter(item => item.chatMessageContent.length > 0 && item.role === 'user')
// Get all files from messages
.flatMap(item => item.chatMessageContent).filter(item => item.type === 'SPECIFIED' && item.content.id != null && item.content.file?.filename != null)
// Transform to final format
.map(item => ({
  id: item.content.id,
  name: item.content.file!.filename
}));