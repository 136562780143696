import { RequireOnlyOne } from '@kindo/universal';
import { Icon, Icons, TextIconColor, Typography, TypographyWeight } from '..';
import { cn } from '../../../utils/tailwind.utils';
import { Button, ButtonType } from '~/components/core';

// https://mui.com/material-ui/react-alert/
export enum BannerType {
  INFO = 'INFO',
  WARNING = 'WARNING',
}

// Banner type configurations
const BANNER_CONFIG: Record<BannerType, {
  bg: string;
  icon: Icon;
  textColor: TextIconColor;
}> = {
  [BannerType.WARNING]: {
    icon: Icon.WARNING,
    bg: 'bg-yellow-400',
    textColor: TextIconColor.PRIMARY
  },
  [BannerType.INFO]: {
    icon: Icon.INFO,
    bg: 'bg-blue-500',
    textColor: TextIconColor.PRIMARY
  }
};
interface BaseBannerButtonProps {
  href: string;
  label: string;
  onClick: () => void;
}
type BannerButtonProps = RequireOnlyOne<BaseBannerButtonProps, 'onClick' | 'href'>;
interface BannerProps {
  text: string;
  type: BannerType;
  redirectButton?: BannerButtonProps;
}

// Subcomponent for the redirect button
const RedirectButton: React.FC<{
  buttonProps: BannerButtonProps;
}> = ({
  buttonProps
}) => {
  const {
    label,
    href,
    onClick
  } = buttonProps;
  if (href) {
    return <Button href={href} label={label} type={ButtonType.SOLID} />;
  }
  if (onClick) {
    return <Button label={label} onClick={onClick} type={ButtonType.SOLID} />;
  }
  return null;
};
export const Banner = ({
  text,
  type,
  redirectButton
}: BannerProps) => {
  const {
    icon,
    textColor,
    bg
  } = BANNER_CONFIG[type];
  return <div className={cn('flex w-full items-center justify-center gap-8 p-2', bg)} id="banner-container">
      <div css={{
      "display": "flex",
      "alignItems": "center",
      "gap": "0.5rem"
    }} id="banner-content">
        <Icons color={textColor} icon={icon} />
        <Typography color={textColor} weight={TypographyWeight.MEDIUM}>
          {text}
        </Typography>
      </div>
      {redirectButton && <RedirectButton buttonProps={redirectButton} />}
    </div>;
};
export default Banner;