import _styled from "styled-components";
import { DisplayedChatMessage } from '../Chat.types';
import { Typography, TypographySize, TypographyWeight, TextIconColor, TypographyFont, TypographyAlignment } from '~/components/core';
import { TypographyWrap } from '~/components/core/Typography';
const BatchUserMessageContainer = _styled.div(() => [{
  "position": "relative",
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.5rem"
}, {
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingBottom": "1.25rem",
  "paddingTop": "1rem",
  "@media (min-width: 640px)": {
    "paddingLeft": "5rem",
    "paddingRight": "5rem"
  }
}, {
  "borderBottomWidth": "1px",
  "borderTopWidth": "1px",
  "borderStyle": "dashed",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(68 68 96 / var(--tw-border-opacity))",
  "borderImage": "repeating-linear-gradient(to right,rgb(68,68,96) 0,rgb(68,68,96) 8px,transparent 8px,transparent 16px) 1"
}]);
const MessageContentContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "flex-start",
  "gap": "0.5rem",
  "@media (min-width: 640px)": {
    "flexDirection": "row",
    "gap": "1rem"
  }
});
const NameAndMessageContainer = _styled.div({
  "position": "relative",
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "alignItems": "center",
  "gap": "0.375rem"
});
interface BatchUserMessageProps {
  chatMessage: DisplayedChatMessage;
}
const BatchUserMessage = ({
  chatMessage
}: BatchUserMessageProps) => <BatchUserMessageContainer>
    <MessageContentContainer>
      <NameAndMessageContainer>
        <Typography alignment={TypographyAlignment.CENTER} color={TextIconColor.PRIMARY} font={TypographyFont.HEADING} size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM} wrap={TypographyWrap.PRE_WRAP}>
          {chatMessage.batch?.prompt}
        </Typography>
      </NameAndMessageContainer>
    </MessageContentContainer>
  </BatchUserMessageContainer>;
export default BatchUserMessage;