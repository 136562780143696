import { CHAT_AGENT_TOOLS, ContentMode, FeatureFlags, FileType, Tool, TOOL_DESCRIPTIONS, TOOL_DISPLAY_NAMES } from '@kindo/universal';
import { useFlag } from '@unleash/nextjs';
import { useEffect, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import useAutoGrow from '../../hooks/useAutoGrow';
import { FileResource } from '../Chat/AddFileModal';
import { ButtonType, Icon, IconButton, IconButtonType, Icons, LoadingSpinner, LoadingSpinnerSize, Select, Size, TextIconColor, Toggle, ToolTip, Typography, TypographyCasing, TypographyFont, TypographySize } from '../core';
import { withLink } from '../core/hocs';
import { Dropdown, DropdownPlacement } from '../Dropdown';
import { ModelSelectionModal } from '../modals';
import { DlpFiltersConfig } from '../SettingsModal/SecuritySettings/OrgSecurityControls/DlpFilters/DlpFiltersConfig/DlpFilters.types';
import { AddFilesButton } from './AddFilesButton';
import { LoadingStopButton } from './LoadingStopButton';
import { promptBarInputContainerStyles, promptBarInputFlexContainerStyles, promptSubmitButtonStyles, textAreaStyles } from './PromptBar.styles';
import { getEnteredFilesFromMessages } from './PromptBar.utils';
import { getContentDirectFetchUrl, LocalStorageKey } from '~/constants';
import { useAppSelector } from '~/hooks';
import useEnabledTools from '~/hooks/useEnabledTools';
import useLocalStorage from '~/hooks/useLocalStorage';
import { chatActions } from '~/redux/reducers/chatSlice';
import { nextTrpc } from '~/trpc';
import { Workflow } from '~/types';
import { cn } from '~/utils/tailwind.utils';
interface PromptBarProps {
  chatId: string | undefined;
  maxHeight: number;
  onStopGeneration: () => void;
  onSubmit: (args: {
    message: string;
    selectedFiles: FileResource[];
  }) => void;
  autoFocus?: boolean;
  disableTextInput?: boolean;
  disabledSubmit?: boolean;
  dlpFilters?: DlpFiltersConfig | undefined;
  loading?: boolean;
  placeholder?: string;
  tooltip?: string | undefined;
  workflow?: Workflow | undefined;
}
export const PromptBar = ({
  chatId,
  onSubmit,
  onStopGeneration,
  disabledSubmit = false,
  disableTextInput = false,
  dlpFilters,
  placeholder,
  loading = false,
  maxHeight,
  autoFocus = false,
  tooltip,
  workflow
}: PromptBarProps) => {
  // State
  const [value, setValue] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<FileResource[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const isPusherStreamingEnabled = useFlag(FeatureFlags.PUSHER_STREAMING);
  // Redux
  const dispatch = useDispatch();
  const setContentMode = (contentMode: ContentMode) => dispatch(chatActions.setContentMode({
    contentMode
  }));
  const {
    contentMode
  } = useAppSelector(state => state.chat);
  const setSelectedFilesAndContentMode = (newSelectedFiles: FileResource[]) => {
    setSelectedFiles(newSelectedFiles);
    if (newSelectedFiles.length === 0 || newSelectedFiles.filter(file => file.fileType === FileType.CSV).length === 0) {
      setContentMode(ContentMode.ALL);
    }
  };

  // Ref
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [chatModel, setChatModel] = useLocalStorage<LocalStorageKey.CHAT_MODEL>(LocalStorageKey.CHAT_MODEL);
  const {
    enabledToolsArray,
    setEnabledToolsArray
  } = useEnabledTools();

  // Queries
  const {
    data: enteredFiles
  } = nextTrpc.chats.getChatMessages.useQuery({
    chatId: chatId || ''
  }, {
    enabled: !!chatId,
    select: getEnteredFilesFromMessages
  });

  // Feature flags
  const isWebSearchEnabled = useFlag(FeatureFlags.WEB_SEARCH_TOOL);
  const isUrlScrapeEnabled = useFlag(FeatureFlags.URL_SCRAPE_TOOL);
  const numFiles = selectedFiles.length + (enteredFiles?.length ?? 0);
  const oneCsvSelected = selectedFiles.filter(file => file.fileType === FileType.CSV).length === 1;
  const areMultipleFilesSelected = selectedFiles.length > 1;
  const isContentModeSelectDisabled = !areMultipleFilesSelected && !oneCsvSelected;

  // CSV Row-By-Row is disabled if Pusher streaming is not enabled because
  // the row by row streaming is handled by Pusher
  const isCSVRowByRowSelectDisabled = !isPusherStreamingEnabled || !oneCsvSelected;
  const toolDisabled = (tool: Tool) => {
    switch (tool) {
      case Tool.URL_SCRAPE:
        return dlpFilters?.url === 'REDACT' || !isUrlScrapeEnabled;
      case Tool.WEB_SEARCH:
        return !isWebSearchEnabled;
      case Tool.LIBRARY_SEARCH:
      default:
        return false;
    }
  };
  const getToolTipContent = (tool: Tool): string => {
    switch (tool) {
      case Tool.URL_SCRAPE:
        if (dlpFilters?.url === 'REDACT') return "URL is redacted by your organization's DLP settings";
        if (!isUrlScrapeEnabled) return 'Web scraping is not setup for use by your organization';
        return TOOL_DESCRIPTIONS[tool];
      case Tool.WEB_SEARCH:
        return isWebSearchEnabled ? TOOL_DESCRIPTIONS[tool] : 'Web search is not set up for use by your organization';
      case Tool.LIBRARY_SEARCH:
      default:
        return TOOL_DESCRIPTIONS[tool];
    }
  };
  useEffect(() => {
    textAreaRef.current?.focus();
  }, [selectedFiles.length]);
  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;
    setValue(val);
  };
  // TODO: Delete this hook once we replace with TextField component,
  // which has auto-grow automatically
  useAutoGrow(textAreaRef.current, value, 0, maxHeight);
  const handleSubmit = () => {
    if (!value || loading || disabledSubmit) {
      return;
    }

    // Blur the textarea to dismiss mobile keyboard
    textAreaRef.current?.blur();
    onSubmit({
      message: value,
      selectedFiles
    });
    setValue('');
    setSelectedFilesAndContentMode([]);

    // Only focus if not on mobile to prevent keyboard from popping up again
    if (!isMobileOnly) {
      textAreaRef.current?.focus();
    }
  };
  const handleToolToggle = (tool: Tool) => {
    const isToolEnabled = enabledToolsArray.includes(tool);
    if (isToolEnabled) {
      const updatedToolsArray = enabledToolsArray.filter((enabledTool: Tool) => enabledTool !== tool);
      setEnabledToolsArray(updatedToolsArray);
    } else {
      enabledToolsArray.push(tool);
      setEnabledToolsArray(enabledToolsArray);
    }
  };
  const handleKeyDown = (evt: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // If user presses backspace without any text, remove the last file
    if (evt.key === 'Backspace' && !evt.currentTarget.value) {
      setSelectedFilesAndContentMode(selectedFiles.slice(0, selectedFiles.length - 1));
    }

    // If user presses enter without shift, submit the form
    if (evt.key === 'Enter' && !evt.shiftKey) {
      evt.preventDefault();
      evt.stopPropagation();
      handleSubmit();
    }
  };
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };
  return <div className={cn('flex w-full flex-col gap-3', isMobileOnly && 'px-2')} data-testid="prompt-bar-container">
      <div css={{
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "space-between",
      "gap": "1rem"
    }} data-testid="prompt-config-container">
        <div css={{
        "display": "flex",
        "alignItems": "center",
        "gap": "0.5rem"
      }} data-testid="prompt-config-select-container">
          <ModelSelectionModal chatModelIdentifier={chatModel} onChange={setChatModel} placeholderLabel="Select AI Model to Chat" prefix="Chat Model: " />
          {!isMobileOnly && <>
              <Select<ContentMode> disabled={isContentModeSelectDisabled} onChange={(val: ContentMode) => {
            setContentMode(val);
          }} options={[{
            label: 'For all files',
            value: ContentMode.ALL,
            tooltip: 'Get one response for all files.\nAll rows of the CSV are used at once.'
          }, {
            disabled: !areMultipleFilesSelected,
            label: 'On each file',
            value: ContentMode.PARALLEL,
            tooltip: 'Get a response for each file'
          }, {
            disabled: isCSVRowByRowSelectDisabled,
            label: 'CSV Row-By-Row',
            value: ContentMode.CSV_ROW_BY_ROW,
            tooltip: 'Get a response for each row in the CSV.\nAdditional attached files will be used as context.'
          }]} placeholderLabel="Select single response for all files, for each file, or for each row in a CSV" tooltip={isContentModeSelectDisabled ? 'Select multiple files to switch file chat mode' : ''} value={contentMode} />
              <Dropdown placement={DropdownPlacement.TOP_START} trigger={{
            buttonProps: {
              startIcon: Icon.GROUP,
              label: 'Tools',
              type: enabledToolsArray.length ? ButtonType.SOLID_GRADIENT : ButtonType.OUTLINED
            },
            hideChevron: false
          }}>
                <div css={{
              "display": "flex",
              "flexDirection": "column",
              "gap": "0.5rem"
            }} data-testid="tools-dropdown-container">
                  {CHAT_AGENT_TOOLS.map((tool: Tool) => <ToolTip content={getToolTipContent(tool)} key={tool}>
                      <div css={{
                  "display": "flex",
                  "alignItems": "center",
                  "gap": "0.5rem",
                  "padding": "0.25rem"
                }} data-testid={`tool-toggle-${tool.toLowerCase()}`} key={tool}>
                        <Toggle checked={enabledToolsArray.includes(tool) && !toolDisabled(tool)} disabled={toolDisabled(tool)} onToggle={() => handleToolToggle(tool)} size={Size.SMALL} />
                        <Typography color={TextIconColor.PRIMARY} size={TypographySize.SMALL}>
                          {TOOL_DISPLAY_NAMES[tool]}
                        </Typography>
                      </div>
                    </ToolTip>)}
                </div>
              </Dropdown>
            </>}
        </div>
        <div css={{
        "display": "flex",
        "alignItems": "center",
        "gap": "0.5rem"
      }} data-testid="prompt-config-files-container">
          <Dropdown disabled={!numFiles} placement={DropdownPlacement.TOP_END} trigger={{
          buttonProps: {
            disabled: !numFiles,
            label: `${numFiles === 1 ? 'File' : 'Files'}`,
            prefix: `${numFiles}`,
            textIconColor: TextIconColor.PRIMARY,
            type: ButtonType.OUTLINED
          },
          hideChevron: false
        }}>
            <div css={{
            "display": "flex",
            "maxWidth": "20rem",
            "flexDirection": "column",
            "rowGap": "0.25rem",
            "paddingLeft": "0.25rem",
            "paddingRight": "0.25rem"
          }} data-testid="files-dropdown-container">
              {/* Displays all specified content (editable) */}
              <div css={{
              "display": "flex",
              "alignItems": "center",
              "borderBottomWidth": "0.063rem",
              "--tw-border-opacity": "1",
              "borderColor": "rgb(150 148 181 / var(--tw-border-opacity))",
              "paddingTop": "0.25rem",
              "paddingBottom": "0.25rem"
            }} data-testid="files-title-selected">
                <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.SECONDARY} font={TypographyFont.HEADING} size={TypographySize.X_SMALL}>
                  Selected Files
                </Typography>
              </div>
              {selectedFiles.map((file: FileResource) => <div css={{
              "display": "flex",
              "alignItems": "center",
              "justifyContent": "space-between",
              "borderRadius": "0.125rem",
              "padding": "0.25rem",
              "&:hover": {
                "--tw-bg-opacity": "1",
                "backgroundColor": "rgb(53 17 245 / var(--tw-bg-opacity))"
              }
            }} data-testid={`selected-file-${file.id}`} key={file.id}>
                  <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.PRIMARY} font={TypographyFont.INTERACTIVE} size={TypographySize.SMALL}>
                    {file.name}
                  </Typography>
                  <IconButton icon={Icon.CLOSE} onClick={() => {
                setSelectedFilesAndContentMode(selectedFiles.filter(f => f.id !== file.id));
              }} size={Size.MEDIUM} type={IconButtonType.SOLID} />
                </div>)}
              {/* Displays all files entered into the conversation (non-editable) */}
              <div css={{
              "display": "flex",
              "alignItems": "center",
              "borderBottomWidth": "0.063rem",
              "--tw-border-opacity": "1",
              "borderColor": "rgb(150 148 181 / var(--tw-border-opacity))",
              "paddingTop": "0.25rem",
              "paddingBottom": "0.25rem"
            }} data-testid="files-title-entered">
                <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.SECONDARY} font={TypographyFont.HEADING} size={TypographySize.X_SMALL}>
                  Entered Files
                </Typography>
              </div>
              <div css={{
              "marginTop": "0.5rem",
              "marginBottom": "0.5rem",
              "display": "flex",
              "alignItems": "center",
              "columnGap": "0.5rem",
              "borderRadius": "0.125rem",
              "--tw-bg-opacity": "1",
              "backgroundColor": "rgb(21 23 46 / var(--tw-bg-opacity))",
              "padding": "0.5rem"
            }} data-testid="entered-files-tooltip">
                <Icons color={TextIconColor.PRIMARY} icon={Icon.INFO} size={Size.MEDIUM} />
                <Typography color={TextIconColor.PRIMARY} font={TypographyFont.PARAGRAPH} italic size={TypographySize.SMALL}>
                  After files enter the conversation, they can't be removed
                </Typography>
              </div>
              {(enteredFiles ?? []).map((file: {
              id: string;
              name: string;
            }) => <div css={{
              "display": "flex",
              "alignItems": "center",
              "justifyContent": "space-between",
              "borderRadius": "0.125rem",
              "padding": "0.25rem",
              "&:hover": {
                "--tw-bg-opacity": "1",
                "backgroundColor": "rgb(53 17 245 / var(--tw-bg-opacity))"
              }
            }} data-testid={`entered-file-${file.id}`} key={file.id}>
                    <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.SECONDARY} font={TypographyFont.INTERACTIVE} size={TypographySize.SMALL}>
                      {file.name}
                    </Typography>
                    {withLink(<IconButton icon={Icon.LINK} onClick={() => {}} size={Size.MEDIUM} type={IconButtonType.SOLID} />, {
                href: getContentDirectFetchUrl({
                  contentId: file.id,
                  isDownload: false,
                  isPlaintext: false
                }),
                newTab: true
              })}
                  </div>)}
            </div>
          </Dropdown>
        </div>
      </div>
      <div className={promptBarInputContainerStyles({
      disabled: disabledSubmit,
      focused: isFocused
    })} data-testid="prompt-input-container">
        <ToolTip content={tooltip ?? ''}>
          <div className={promptBarInputFlexContainerStyles({
          hasContent: isFocused
        })} data-testid="prompt-input-flex-container">
            {!isMobileOnly && <AddFilesButton data-id="workflow_plus" disabled={disabledSubmit} onFileSelect={newSelected => {
            setSelectedFilesAndContentMode(newSelected);
            textAreaRef.current?.focus();
          }} selectedFiles={selectedFiles} />}
            <div css={{
            "display": "flex",
            "flex": "1 1 0%",
            "alignItems": "center",
            "justifyContent": "space-between",
            "gap": "0.5rem"
          }} data-testid="prompt-input-text-container">
              <textarea autoFocus={autoFocus} className={textAreaStyles({
              disabled: disableTextInput,
              empty: !value
            })} data-testid="prompt-textarea" disabled={disableTextInput} name="query" onBlur={handleBlur} onChange={handleChange} onFocus={handleFocus} onKeyDown={handleKeyDown} placeholder={selectedFiles.length ? '' : placeholder} ref={textAreaRef} required rows={1} style={{
              transition: 'all 0.2s ease'
            }} value={value} />
            </div>
            <div css={{
            "display": "flex",
            "alignItems": "center",
            "gap": "1rem",
            "paddingLeft": "1rem",
            "paddingRight": "1rem"
          }} data-testid="prompt-input-buttons-container">
              {!loading && !!value && <IconButton icon={Icon.CLOSE} onClick={() => {
              setValue('');
              if (textAreaRef.current) {
                textAreaRef.current.value = '';
                textAreaRef.current.focus();
              }
            }} size={Size.SMALL} type={IconButtonType.SOLID} />}
              {!loading && <button className={promptSubmitButtonStyles({
              disabled: disabledSubmit
            })} data-testid="prompt-submit-button" disabled={disabledSubmit} onClick={handleSubmit} style={{
              transition: 'color 0.2s ease'
            }}>
                  <Icons color={disabledSubmit || !textAreaRef.current?.value ? TextIconColor.CLEAR_MEDIUM : TextIconColor.PRIMARY} icon={Icon.SEND} size={Size.LARGE} />
                </button>}
              {/* If workflow is not defined, then we are in chat mode */}
              {loading && !workflow && <LoadingStopButton onStopClick={onStopGeneration} />}
              {/* If workflow is defined, then we are in workflow mode */}
              {/* TODO: Add capability for stopping workflow */}
              {loading && workflow && <LoadingSpinner size={LoadingSpinnerSize.LARGE} />}
            </div>
          </div>
        </ToolTip>
      </div>
    </div>;
};
export default PromptBar;