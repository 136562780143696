import _styled from "styled-components";
import { CerbosWorkflowAction } from '@kindo/universal';
import { formatDistanceToNowStrict } from 'date-fns';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Button, ButtonType, Icon, Size, TextField, TextFieldType, TextIconColor, Typography, TypographyCasing, TypographyFont, TypographySize, TypographyWeight } from '~/components/core';
import { Badge } from '~/components/core/Badge';
import { Dropdown, DropdownItem } from '~/components/Dropdown';
import { NavButton } from '~/components/Nav/NavButton';
import { CopyButton } from '~/components/shared';
import { BASE_NEXT_URL, getWorkflowBuilderRoute, ROOT_ROUTE, WORKFLOW_ID_QUERY_PARAM, WORKSTATION_ROUTE } from '~/constants';
import { ToastType, useAppDispatch, useCanShareWorkflow, useToast } from '~/hooks';
import { Modal, modalActions } from '~/redux/reducers/modalSlice';
import { nextTrpc } from '~/trpc';
import { Workflow } from '~/types';
const WorkstationHeaderContainer = _styled.div(() => [{
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.25rem",
  "borderRadius": "0.25rem",
  "borderWidth": "0.063rem",
  "borderColor": "#6761fa5e",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(22 18 61 / var(--tw-bg-opacity))",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem"
}, isMobileOnly && {
  "marginTop": "1rem"
}]);
const BadgeAndUpdatedAtContainer = _styled.div({
  "display": "flex",
  "flexShrink": "0",
  "flexDirection": "row",
  "alignItems": "center",
  "gap": "1.25rem"
});
const ActionButtonsContainer = _styled.div({
  "display": "flex",
  "flexShrink": "0",
  "alignItems": "center",
  "gap": "0.5rem"
});
const DescriptionAndActionsContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "row",
  "alignItems": "center",
  "justifyContent": "space-between",
  "gap": "0.5rem",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem",
  "paddingLeft": "0.5rem"
});
const TitleAndBadgeContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "minWidth": "0px",
  "flexDirection": "row",
  "alignItems": "center",
  "justifyContent": "space-between",
  "gap": "1rem"
});
interface WorkstationHeaderProps {
  chatId: string | undefined;
  refetchChat: () => void;
  title: string;
  workflow: Workflow | undefined;
}
const WorkstationHeader: React.FC<WorkstationHeaderProps> = ({
  chatId,
  refetchChat,
  title,
  workflow
}) => {
  const workflowId = workflow?.id;
  const [chatTitle, setChatTitle] = useState(title);

  // Redux
  const dispatch = useAppDispatch();
  const {
    enqueueToast
  } = useToast();
  const openShareWorkflowModal = () => {
    dispatch(modalActions.openModal({
      type: Modal.SHARE_WORKFLOW,
      workflowId: workflowId ?? ''
    }));
  };
  const openShareChatModal = () => {
    dispatch(modalActions.openModal({
      type: Modal.SHARE_CHAT,
      chatId: chatId ?? ''
    }));
  };

  // Custom hooks
  const router = useRouter();
  const {
    canShareWorkflow
  } = useCanShareWorkflow(workflowId);

  // Queries
  const {
    data: isAllowedToEditWorkflow
  } = nextTrpc.workflows.isAllowed.useQuery({
    id: workflowId ?? '',
    action: CerbosWorkflowAction.EDIT_WORKFLOW
  }, {
    enabled: !!workflowId
  });

  // Mutations
  const updateTitleMutation = nextTrpc.chats.updateTitle.useMutation({
    onMutate: data => {
      setChatTitle(data.title);
    },
    onSuccess: () => {
      refetchChat();
      enqueueToast({
        message: 'Title updated successfully',
        type: ToastType.SUCCESS
      });
    },
    onError: () => {
      setChatTitle(title);
      enqueueToast({
        message: 'An error occurred while updating the title',
        type: ToastType.ERROR
      });
    }
  });

  // Handlers
  const handleTitleChange = (newTitle: string) => {
    if (!chatId || newTitle.trim() === title) {
      return;
    }
    if (newTitle.trim() === '') {
      setChatTitle(title);
      return;
    }
    updateTitleMutation.mutate({
      chatId,
      title: newTitle
    });
  };
  const handleRestartWorkflow = () => {
    // Remove chatId if it exists
    const {
      chatId: _paramChatId,
      ...query
    } = router.query;
    void router.push({
      pathname: router.pathname,
      query
    });
  };
  const shareWorkflowLink = workflowId ? `${BASE_NEXT_URL}${WORKSTATION_ROUTE}?${WORKFLOW_ID_QUERY_PARAM}=${workflowId}` : '';
  const isPublic = !!workflow?.publicRole;
  const renderActionButtons = () => <ActionButtonsContainer>
      {/* If the user doesn't have permission to share */}
      {/* But the workflow is public, let them copy the link */}
      {workflowId && isPublic && !canShareWorkflow && <CopyButton data-id="workflow_copy" icon={Icon.SHARE} label="Share Agent" successText="Agent Link Copied" text={shareWorkflowLink} />}
      {!!workflowId && <>
          {<Dropdown data-id="workflow_share" trigger={{
        buttonProps: {
          startIcon: Icon.SHARE,
          label: 'Share',
          size: Size.SMALL,
          type: ButtonType.OUTLINED
        },
        hideChevron: true
      }}>
              <DropdownItem disabled={!canShareWorkflow} onClick={() => openShareWorkflowModal()} title="Share Agent" tooltip={!canShareWorkflow ? 'You do not have permission to share this agent' : undefined} />
              <DropdownItem onClick={() => openShareChatModal()} title="Share Chat" />
            </Dropdown>}
          <Button data-id="workflow_restart" endIcon={Icon.RELOAD} label="Restart" onClick={handleRestartWorkflow} size={Size.SMALL} type={ButtonType.OUTLINED} />
          {isAllowedToEditWorkflow && <Button data-id="workflow_edit" endIcon={Icon.EDIT} href={getWorkflowBuilderRoute(workflowId)} label="Edit Agent" size={Size.SMALL} type={ButtonType.OUTLINED} />}
        </>}

      {isMobileOnly && <NavButton data-id="nav_new_chat" hideActive icon={Icon.RELOAD} route={ROOT_ROUTE} />}
    </ActionButtonsContainer>;
  return <WorkstationHeaderContainer>
      <TitleAndBadgeContainer>
        <div css={{
        "flex": "1 1 0%"
      }}>
          <TextField animateStartIcon font={TypographyFont.INTERACTIVE} fullWidth onBlur={() => handleTitleChange(chatTitle)} onChange={value => setChatTitle(value)} placeholder="ENTER A NEW TITLE" showFocusBottomBorder size={TypographySize.MEDIUM} startIcon={Icon.EDIT} startIconFocusColor={TextIconColor.HIGHLIGHT} textWeight={TypographyWeight.MEDIUM} type={TextFieldType.SIMPLE} value={chatTitle.toLocaleUpperCase()} />
        </div>
        <BadgeAndUpdatedAtContainer>
          {!workflowId && !!chatId && <Button data-id="chat_share" endIcon={Icon.SHARE} label="Share" onClick={openShareChatModal} size={Size.SMALL} type={ButtonType.OUTLINED} />}
          {workflow?.category && <Badge label={workflow?.category} />}
          {workflow?.updatedAt && <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.SECONDARY} font={TypographyFont.HEADING} size={TypographySize.X_SMALL}>
              {formatDistanceToNowStrict(new Date(workflow?.updatedAt), {
            addSuffix: true
          })}
            </Typography>}
        </BadgeAndUpdatedAtContainer>
      </TitleAndBadgeContainer>
      {!!workflowId && <DescriptionAndActionsContainer>
          {workflow?.description && <Typography color={TextIconColor.SECONDARY} size={TypographySize.SMALL}>
              {workflow.description}
            </Typography>}
          {renderActionButtons()}
        </DescriptionAndActionsContainer>}
    </WorkstationHeaderContainer>;
};
export default WorkstationHeader;