import { FeatureFlags, Tool } from '@kindo/universal';
import { useFlag, useFlagsStatus } from '@unleash/nextjs';
import { useEffect } from 'react';

import useLocalStorage from './useLocalStorage';

import { LocalStorageKey } from '~/constants';

// Custom hook to manage and respect enterprise-specific feature flags for enabled tools
const useEnabledTools = () => {
  const { flagsReady } = useFlagsStatus();
  // Check tool-related feature flags
  const isUrlScrapeEnabled = useFlag(FeatureFlags.URL_SCRAPE_TOOL);
  const isWebSearchEnabled = useFlag(FeatureFlags.WEB_SEARCH_TOOL);

  const toolFeatureFlagValues = {
    [Tool.LIBRARY_SEARCH]: true,
    [Tool.URL_SCRAPE]: isUrlScrapeEnabled,
    [Tool.WEB_SEARCH]: isWebSearchEnabled
  } as const satisfies Record<Tool, boolean>;

  const [enabledTools, setEnabledTools] =
    useLocalStorage<LocalStorageKey.ENABLED_TOOLS>(
      LocalStorageKey.ENABLED_TOOLS
    );

  const enabledToolsArray = JSON.parse(enabledTools);

  const setEnabledToolsArray = (updatedToolsArray: Tool[]) =>
    setEnabledTools(JSON.stringify(updatedToolsArray));

  // If flags are in the ready state, then filter out any tools that are disabled according to the feature flags
  useEffect(() => {
    if (flagsReady) {
      const updatedToolsArray = enabledToolsArray.filter(
        (tool: Tool) => !!toolFeatureFlagValues[tool]
      );
      setEnabledTools(JSON.stringify(updatedToolsArray));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagsReady]);

  return { enabledToolsArray, setEnabledToolsArray };
};

export default useEnabledTools;
