import _styled from "styled-components";
import { DisplayedChatMessage, ResponseChatMessage } from '../Chat.types';
import BatchResponseMessage from './BatchResponseMessage';
import BatchUserMessage from './BatchUserMessage';
import { Badge } from '~/components/core/Badge';
import { BadgeColor } from '~/components/core/Badge/Badge.consts';
const BatchContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.75rem"
});
const BatchMessagesContainer = _styled.div({
  "gap": "0px"
});
const BatchMessage = _styled.div<{
  $isFirstResponse?: boolean;
  $isLastResponse?: boolean;
}>(({
  $isFirstResponse
}) => [{
  "display": "flex",
  "flexDirection": "column"
}, !$isFirstResponse && {
  "--tw-border-opacity": "0.1",
  "borderBottomColor": "rgb(30 33 65 / var(--tw-border-opacity))"
}]);
type ChatMessageBatchProps = {
  batchResponseMessages: ResponseChatMessage[];
  message: DisplayedChatMessage;
};
const ChatMessageBatch = ({
  message,
  batchResponseMessages
}: ChatMessageBatchProps) => <BatchContainer>
    <BatchUserMessage chatMessage={message} />
    <BatchMessagesContainer>
      <Badge color={BadgeColor.PRIMARY_BRIGHT} label={message.modelDisplayName || 'AI'} />
      {batchResponseMessages.map((response, index) => <BatchMessage $isFirstResponse={index === 0} key={response.id}>
          <BatchResponseMessage chatMessage={response} fileNumber={index + 1} key={response.id} totalBatchedResponses={batchResponseMessages.length} />
        </BatchMessage>)}
    </BatchMessagesContainer>
  </BatchContainer>;
export default ChatMessageBatch;