import { Icon, IconButton, IconButtonType, LoadingSpinner, LoadingSpinnerSize, Size, TextIconColor } from '~/components/core';
type LoadingStopButtonProps = {
  onStopClick: () => void;
  'data-id'?: string;
};

// TODO: Either stop code execution with or disable this button
const LoadingStopButton = ({
  onStopClick,
  'data-id': dataId
}: LoadingStopButtonProps) => <div css={{
  "position": "relative",
  "height": "2rem",
  "width": "2rem"
}} data-testid="loading-stop-button-container">
    <div css={{
    "position": "absolute",
    "zIndex": "10",
    "display": "flex",
    "height": "2rem",
    "width": "2rem",
    "alignItems": "center",
    "justifyContent": "center"
  }} data-testid="icon-button-container">
      <IconButton color={TextIconColor.PRIMARY} data-id={dataId ?? ''} icon={Icon.CLOSE} onClick={onStopClick} size={Size.SMALL} type={IconButtonType.SIMPLE} />
    </div>
    <div css={{
    "position": "absolute",
    "zIndex": "0",
    "height": "2rem",
    "width": "2rem"
  }} data-testid="loading-spinner-container">
      <LoadingSpinner size={LoadingSpinnerSize.LARGE} />
    </div>
  </div>;
export default LoadingStopButton;