import { tv } from 'tailwind-variants';
import { TEXT_COLOR_CLASSES, TextIconColor } from '~/components/core';

// Input container with focus and disabled states
export const promptBarInputContainerStyles = tv({
  base: 'shadow-prompt-bar-static relative w-full rounded-lg bg-black transition-all duration-100',
  variants: {
    focused: {
      true: 'shadow-prompt-bar-focus',
      false: 'hover:shadow-prompt-bar-hover'
    },
    disabled: {
      true: 'cursor-not-allowed'
    }
  }
});

// Flex container for prompt input with content state
export const promptBarInputFlexContainerStyles = tv({
  base: 'relative flex h-full flex-row items-center justify-center gap-2 px-2 py-0',
  variants: {
    hasContent: {
      true: 'py-3 transition-all duration-[350ms]',
      false: 'transition-all duration-200 hover:py-1'
    }
  }
});

// Submit button styles
export const promptSubmitButtonStyles = tv({
  base: 'h-full cursor-pointer items-center',
  variants: {
    disabled: {
      true: 'cursor-not-allowed'
    }
  }
});

// TextArea styles
export const textAreaStyles = tv({
  base: `flex-1 cursor-text resize-none bg-transparent py-3 text-base outline-none 
    ${TEXT_COLOR_CLASSES[TextIconColor.PRIMARY]}
    font-ibmPlexMono text-sm 
    [&::placeholder]:${TEXT_COLOR_CLASSES[TextIconColor.CLEAR_MEDIUM]}
    [&::placeholder]:opacity-100 [&::placeholder]:transition-color [&::placeholder]:duration-300
    [&:hover:not(:focus)::placeholder]:${TEXT_COLOR_CLASSES[TextIconColor.PRIMARY_HIGHLIGHT]}
    [&:hover:not(:focus)::placeholder]:opacity-100 [&:hover:not(:focus)::placeholder]:transition-all [&:hover:not(:focus)::placeholder]:duration-300 [&:hover:not(:focus)::placeholder]:ease-in-out
    [&:focus::placeholder]:${TEXT_COLOR_CLASSES[TextIconColor.PRIMARY]} [&:focus::placeholder]:opacity-20 [&:focus::placeholder]:transition-all [&:focus::placeholder]:duration-100 [&:focus::placeholder]:ease`,
  variants: {
    disabled: {
      true: 'cursor-not-allowed'
    },
    empty: {
      true: 'overflow-hidden'
    }
  }
});