import _styled from "styled-components";
import { ChatMessageContentType, FileType, getFileTypeFromMimeType, getRecommendedModelForError, LLM_DISPLAY_NAMES, SupportedLlm, Tool } from '@kindo/universal';
import { Button, ButtonType, Icon, Size, TextIconColor, Typography, TypographyCasing, TypographyFont, TypographySize, TypographyWeight } from '../../core';
import { Markdown } from '../../Markdown';
import { ContentReference } from '../../shared/ContentReference';
import { CopyButton } from '../../shared/CopyButton';
import { ResponseChatMessage } from '../Chat.types';
import { getBadgeColorForMessage, getErrorMessageText, getErrorMessageTitle, getMessageErrorInfo } from '../ChatMessage.utils';
import CodeExecutionResult from './CodeExecutionResult';
import useSmoothStreaming from './useSmoothStreaming';
import { Badge } from '~/components/core/Badge';
import { Dropdown, DropdownItem } from '~/components/Dropdown';
import { CsvViewer } from '~/components/shared/CsvViewer';
import { useAppSelector, useGetEnabledModels, useSlackMessageSharing } from '~/hooks';

// For csv-row-by-row, enable tooltip for response and error columns on CSV output
// so that all text is visible
const CSV_COLUMNS_WITH_TOOLTIP = ['response', 'error'];
const BadgeAndResponseMessageContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column"
});
const ResponseChatMessageContainer = _styled.div<{
  $isError: boolean;
  $isHandledKindoError: boolean;
}>(({
  $isError,
  $isHandledKindoError
}) => [{
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "23px",
  "borderRadius": "0 3px 3px 3px",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(30 33 65 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(13 15 31 / var(--tw-bg-opacity))",
  "paddingLeft": "3rem",
  "paddingRight": "3rem",
  "paddingTop": "2.25rem",
  "paddingBottom": "2.25rem",
  "--tw-backdrop-blur": "blur(53.5px)",
  "WebkitBackdropFilter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)",
  "backdropFilter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)"
}, $isError && {
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(255 51 108 / var(--tw-border-opacity))"
}, $isHandledKindoError && {
  "--tw-border-opacity": "1",
  "borderColor": "rgb(253 235 144 / var(--tw-border-opacity))"
}]);
const ContentAndToolReferencesContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexWrap": "wrap",
  "gap": "0.5rem",
  "borderRadius": "0.5rem",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(22 18 61 / var(--tw-bg-opacity))",
  "paddingLeft": "1.5rem",
  "paddingRight": "1.5rem",
  "paddingTop": "0.75rem",
  "paddingBottom": "0.75rem"
});
const CsvViewerContainer = _styled.div({
  "position": "relative",
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "overflow": "auto",
  "borderRadius": "0.25rem",
  "borderWidth": "1px",
  "borderStyle": "dashed",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(52 45 137 / var(--tw-border-opacity))",
  "--tw-drop-shadow": "drop-shadow(0 1px 1px rgb(0 0 0 / 0.05))",
  "filter": "var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)"
});
const CsvViewerHeaderContainer = _styled.div({
  "display": "flex",
  "flexDirection": "row",
  "alignItems": "center",
  "justifyContent": "space-between",
  "borderTopLeftRadius": "0.25rem",
  "borderTopRightRadius": "0.25rem",
  "borderBottomWidth": "1px",
  "borderStyle": "dashed",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(52 45 137 / var(--tw-border-opacity))",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem",
  "paddingLeft": "0.5rem",
  "paddingRight": "0.25rem"
});
const CsvContentReferenceContainer = _styled.div({
  "display": "flex",
  "flexWrap": "wrap",
  "alignItems": "center",
  "gap": "0.5rem"
});
const MessageTextContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.5rem"
});
const ActionButtons = _styled.div({
  "display": "flex",
  "width": "100%",
  "justifyContent": "space-between",
  "paddingRight": "1rem"
});
const HiddenButtons = _styled.div({
  "display": "flex",
  "alignItems": "center",
  "gap": "0.5rem"
});
const ErrorMessageContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "23px"
});
const RegenerateButtonContainer = _styled.div({
  "display": "flex",
  "flexDirection": "row",
  "gap": "0.5rem"
});
type ResponseChatMessageProps = {
  chatMessage: ResponseChatMessage;
  onRegenerate: (newModel?: SupportedLlm) => void;
  onStreamChange: () => void;
};
const renderNewModelForErrorButton = (message: string, enabledModelIdentifiers: string[], modelIdentifier: string | null, isKindoError: boolean, onRegenerate?: (model: SupportedLlm) => void) => {
  if (!modelIdentifier) return null;
  const recommendedModel = getRecommendedModelForError(message, modelIdentifier, enabledModelIdentifiers, isKindoError);
  if (!recommendedModel) return null;
  return <Button label={`Try with ${LLM_DISPLAY_NAMES[recommendedModel]} instead`} onClick={() => {
    onRegenerate?.(recommendedModel);
  }} size={Size.LARGE} type={ButtonType.OUTLINED} />;
};
const ResponseChatMessageComponent: React.FC<ResponseChatMessageProps> = ({
  chatMessage,
  onRegenerate,
  onStreamChange
}) => {
  // Redux
  const {
    userId
  } = useAppSelector(({
    user
  }) => user);

  // Used for filtering Regenerate button options
  const {
    enabledModels
  } = useGetEnabledModels();
  const enabledModelIdentifiers = enabledModels.map(model => model.identifier);
  // Custom Hooks
  const {
    isSlackConnected,
    slackChannels,
    handleShareToSlack
  } = useSlackMessageSharing();
  const {
    artifacts,
    isStreaming,
    message,
    modelDisplayName,
    modelIdentifier,
    referencedContent,
    state,
    workflowStepNumber,
    codeExecution
  } = chatMessage;
  const {
    text: smoothStreamingText
  } = useSmoothStreaming({
    chatMessage,
    onChange: onStreamChange
  });
  const {
    isError,
    isKindoError,
    isHandledKindoError,
    displayedMessage
  } = getMessageErrorInfo(message, state);
  const toolsUsed = new Set(artifacts?.map(artifact => artifact.tool));
  // NOTE: Special case for handling the toolsUsed set when performing a library search.
  // Since vector search source nodes from the library are stored in a similar manner
  // to both specified content (current and historical) and the knowledge store,
  // we need to explicitly check for the presence of a LIBRARY_SEARCH ChatMessageContentType,
  // which indicates the use of the library search tool.
  if ((referencedContent?.filter(content => content.type === ChatMessageContentType.LIBRARY_SEARCH) ?? []).length > 0) {
    toolsUsed.add(Tool.LIBRARY_SEARCH);
  }
  const inputReferencedContent = referencedContent?.filter(content => content.type !== ChatMessageContentType.OUTPUT);
  const outputReferencedContent = referencedContent?.filter(content => content.type === ChatMessageContentType.OUTPUT && getFileTypeFromMimeType(content.mimeType ?? '') === FileType.CSV);
  const renderMessageContent = () => {
    if (codeExecution) {
      return <CodeExecutionResult chatMessageCodeExecution={codeExecution} />;
    }
    if (isStreaming && smoothStreamingText) {
      return <Markdown chatMessageId={chatMessage.id} isStreaming>
          {smoothStreamingText}
        </Markdown>;
    }
    if (isError) {
      return <ErrorMessageContainer>
          <Typography color={isHandledKindoError ? TextIconColor.WARNING : TextIconColor.ERROR} font={TypographyFont.HEADING} size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD}>
            {getErrorMessageTitle(isHandledKindoError, message)}
          </Typography>
          <Typography color={TextIconColor.PRIMARY} size={TypographySize.MEDIUM}>
            {getErrorMessageText(isHandledKindoError, displayedMessage)}
          </Typography>
          {workflowStepNumber === undefined && <RegenerateButtonContainer>
              <Button label="Retry" onClick={() => {
            onRegenerate?.();
          }} size={Size.LARGE} startIcon={Icon.RELOAD} type={ButtonType.OUTLINED} />
              {renderNewModelForErrorButton(message, enabledModelIdentifiers, modelIdentifier, isKindoError, onRegenerate)}
            </RegenerateButtonContainer>}
        </ErrorMessageContainer>;
    }
    return <Markdown chatMessageId={chatMessage.id} isStreaming={isStreaming}>
        {displayedMessage}
      </Markdown>;
  };
  const getBadgeLabel = () => {
    if (codeExecution) {
      return 'Code Execution Result';
    }
    return modelDisplayName || 'AI';
  };
  const getBadgeColor = () => getBadgeColorForMessage(isError, isHandledKindoError);
  return <BadgeAndResponseMessageContainer>
      <Badge color={getBadgeColor()} label={getBadgeLabel()} />
      <ResponseChatMessageContainer $isError={isError} $isHandledKindoError={isHandledKindoError} className="group/message">
        {(!!inputReferencedContent?.length || !!artifacts?.length) && !chatMessage.batch && !isError && <ContentAndToolReferencesContainer>
              {inputReferencedContent?.map(content => <ContentReference canDownload={content.creatorId === userId} contentId={content.contentId} fileName={content.fileName} key={content.referenceId} source={content.source} tooltip={content.sourceContent} />)}
              {artifacts?.map(artifact => <ContentReference canDownload={false} contentId={artifact.contentId} fileName={artifact.pageTitle} key={artifact.contentId} source={artifact.source} tooltip={artifact.sourceContent} url={artifact.url} />)}
            </ContentAndToolReferencesContainer>}

        <MessageTextContainer>{renderMessageContent()}</MessageTextContainer>
        {!isError && !codeExecution && <ActionButtons>
            <HiddenButtons>
              <CopyButton buttonType={ButtonType.SOLID_COLOR} size={Size.SMALL} text={message} />
              {/*
               Hide regenerate if it is a workflow step for now, to avoid
               revealing the underlying prompt
               */}
              {onRegenerate && workflowStepNumber === undefined && <Button endIcon={Icon.RELOAD} label="Re-generate" onClick={() => onRegenerate?.()} size={Size.SMALL} type={ButtonType.SOLID_COLOR} />}
              {isSlackConnected && <Dropdown highlightTriggerOnHover trigger={Icon.SLACK}>
                  {slackChannels.map(channel => <DropdownItem key={channel.id} onClick={() => handleShareToSlack(channel.id, displayedMessage)} title={`# ${channel.channelName}`} />)}
                </Dropdown>}
            </HiddenButtons>
          </ActionButtons>}
        {/** Output CSVs */}
        {outputReferencedContent?.map(content => <CsvViewerContainer key={content.contentId}>
            <CsvViewerHeaderContainer>
              <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.SECONDARY} font={TypographyFont.HEADING} size={TypographySize.X_SMALL} weight={TypographyWeight.BOLD}>
                CSV
              </Typography>
              <CsvContentReferenceContainer>
                <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.SECONDARY} font={TypographyFont.INTERACTIVE} size={TypographySize.X_SMALL} weight={TypographyWeight.BOLD}>
                  Output file:
                </Typography>
                <ContentReference canDownload={content.creatorId === userId} contentId={content.contentId} fileName={content.fileName} key={content.referenceId} source={content.source} tooltip={content.sourceContent} />
              </CsvContentReferenceContainer>
            </CsvViewerHeaderContainer>
            <CsvViewer columnsWithTooltip={CSV_COLUMNS_WITH_TOOLTIP} contentReference={content} />
          </CsvViewerContainer>)}
      </ResponseChatMessageContainer>
    </BadgeAndResponseMessageContainer>;
};
export default ResponseChatMessageComponent;