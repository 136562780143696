import _styled from "styled-components";
import { ChatListAccessScopeFilter } from '@kindo/universal';
import { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { groupWorkstationHistoryItemsByDate, WORKSTATION_HISTORY_DATE_GROUPS } from '../../WorkstationHistorySidebar.utils';
import { HistoryContainer, GroupContainer, GroupTitle, HistoryItem } from '../HistorySidebarComponentUtils';
import { Button, ButtonType } from '~/components/core/Button';
import { TextIconColor } from '~/components/core/constants';
import { SegmentedControl } from '~/components/core/SegmentedControl';
import { SegmentedControlType } from '~/components/core/SegmentedControl/SegmentedControl.consts';
import { Typography, TypographyWrap } from '~/components/core/Typography';
import { CHAT_ACCESS_SCOPE_FILTER_TO_LABEL, CHAT_ID_QUERY_PARAM, getWorkstationRoute, UNTITLED_CHAT_NAME } from '~/constants';
import { useURLParams } from '~/hooks';
import useUserInfo from '~/hooks/useUserInfo';
import { nextTrpc } from '~/trpc';
const LoadMoreButtonContainer = _styled.div({
  "marginTop": "0.75rem",
  "display": "flex",
  "justifyContent": "center"
});
const SegmentedControlContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "alignItems": "center",
  "justifyContent": "center",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "0.25rem"
});
interface ChatHistoryProps {
  onItemClick: (route: string) => Promise<void>;
}
const ChatHistory = ({
  onItemClick
}: ChatHistoryProps) => {
  // Set state
  const [accessScopeFilter, setAccessScopeFilter] = useState<ChatListAccessScopeFilter>(ChatListAccessScopeFilter.CREATOR_SCOPE);
  // URL Params
  const params = useURLParams<{
    [CHAT_ID_QUERY_PARAM]?: string;
  }>();
  const {
    [CHAT_ID_QUERY_PARAM]: urlChatId
  } = params;
  // Get user info
  const {
    user
  } = useUserInfo();
  // If chat id is in url param, fetch chat
  const {
    data: chatData
  } = nextTrpc.chats.get.useQuery({
    chatId: urlChatId ?? ''
  }, {
    enabled: !!urlChatId
  });
  useEffect(() => {
    if (chatData?.item) {
      if (chatData.item.creatorId !== user?.id) {
        setAccessScopeFilter(ChatListAccessScopeFilter.SHARED_SCOPE);
      }
    }
  }, [chatData, user?.id]);
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading
  } = nextTrpc.chats.list.useInfiniteQuery({
    limit: 50,
    accessScopeFilter
  }, {
    getNextPageParam: lastPage => lastPage.nextCursor
  });
  const chatHistory = data?.pages.flatMap(page => page.items.map(item => ({
    id: item.id,
    creatorName: item?.creator?.name || '',
    title: item.title || UNTITLED_CHAT_NAME,
    timestamp: item?.lastUserInteractionAt || '',
    workflowId: item.workflowId
  }))) ?? [];
  const groupedChats = groupWorkstationHistoryItemsByDate(chatHistory);
  const handleMobileClick = (route: string) => {
    void onItemClick(route);
  };
  return <HistoryContainer>
      <SegmentedControlContainer>
        <SegmentedControl onClick={setAccessScopeFilter} segmentOptions={[ChatListAccessScopeFilter.CREATOR_SCOPE, ChatListAccessScopeFilter.SHARED_SCOPE].map(scope => ({
        label: CHAT_ACCESS_SCOPE_FILTER_TO_LABEL[scope],
        value: scope
      }))} type={SegmentedControlType.SOLID_COLOR} value={accessScopeFilter} />
      </SegmentedControlContainer>
      {WORKSTATION_HISTORY_DATE_GROUPS.map(({
      key,
      title
    }) => {
      const chats = groupedChats[key];
      return chats && chats.length > 0 ? <GroupContainer key={key}>
            <GroupTitle title={title} />
            {chats.map(chat => {
          const route = getWorkstationRoute({
            chatId: chat.id,
            workflowId: chat.workflowId || undefined
          });
          return <HistoryItem key={chat.id}>
                  <Typography color={urlChatId === chat.id ? TextIconColor.PRIMARY : TextIconColor.SECONDARY} href={isMobileOnly ? '' : route} onClick={() => {
              if (isMobileOnly) {
                handleMobileClick(route);
              }
            }} wrap={TypographyWrap.NO_WRAP}>
                    {chat.title}
                  </Typography>
                </HistoryItem>;
        })}
          </GroupContainer> : null;
    })}
      {hasNextPage && <LoadMoreButtonContainer>
          <Button label={isLoading ? 'Loading...' : 'Load more'} onClick={() => {
        void fetchNextPage();
      }} type={ButtonType.SOLID_COLOR} />
        </LoadMoreButtonContainer>}
    </HistoryContainer>;
};
export default ChatHistory;