import _styled from "styled-components";
import { ChatListWorkflowRunTypeFilter, WorkflowType } from '@kindo/universal';
import { useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { getWorkflowRunTypeOfChat, groupWorkstationHistoryItemsByDate, WORKSTATION_HISTORY_DATE_GROUPS } from '../../WorkstationHistorySidebar.utils';
import { HistoryContainer, GroupContainer, GroupTitle, HistoryItem } from '../HistorySidebarComponentUtils';
import { Button, ButtonType, Select } from '~/components/core';
import { TextIconColor } from '~/components/core/constants';
import { Typography, TypographyFont, TypographySize, TypographyWeight } from '~/components/core/Typography';
import { CHAT_WORKFLOW_RUN_TYPE_TO_LABEL, CHAT_ID_QUERY_PARAM, getWorkstationRoute, UNTITLED_WORKFLOW_NAME, WORKFLOW_TYPE_TO_ABBREVIATION } from '~/constants';
import { useURLParams } from '~/hooks/useUrlParams';
import { nextTrpc } from '~/trpc';
interface WorkflowRunHistoryProps {
  onItemClick: (route: string) => Promise<void>;
}
const SelectContainer = _styled.div({
  "marginTop": "0.75rem",
  "paddingLeft": "1rem",
  "paddingRight": "1rem"
});
const AgentBadge = _styled.span({
  "display": "inline-flex",
  "alignItems": "center",
  "borderRadius": "0.125rem",
  "backgroundColor": "rgb(255 255 255 / 0.1)",
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem"
});
const LoadMoreButtonContainer = _styled.div({
  "marginTop": "0.75rem",
  "display": "flex",
  "justifyContent": "center"
});
interface ChatFromWorkflowRunHistoryItem {
  creatorName: string;
  id: string;
  timestamp: string;
  title: string;
  workflowId: string | null;
  workflowRunType: WorkflowType;
}
const WorkflowRunHistory = ({
  onItemClick
}: WorkflowRunHistoryProps) => {
  // URL Params
  const params = useURLParams<{
    [CHAT_ID_QUERY_PARAM]?: string;
  }>();
  const {
    [CHAT_ID_QUERY_PARAM]: urlChatId
  } = params;
  const [workflowRunTypeFilter, setWorkflowRunTypeFilter] = useState<ChatListWorkflowRunTypeFilter>(ChatListWorkflowRunTypeFilter.ALL);
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading
  } = nextTrpc.chats.list.useInfiniteQuery({
    limit: 50,
    workflowRunTypeFilter
  }, {
    getNextPageParam: lastPage => lastPage.nextCursor
  });
  const workflowRunHistory: ChatFromWorkflowRunHistoryItem[] = data?.pages.flatMap(page => page.items.filter(chat => !!chat.workflowId).map(chat => ({
    id: chat.id,
    creatorName: chat?.creator?.name || '',
    title: chat.title || UNTITLED_WORKFLOW_NAME,
    timestamp: chat?.lastUserInteractionAt || '',
    workflowId: chat.workflowId,
    workflowRunType: getWorkflowRunTypeOfChat(chat)
  }))) ?? [];
  const groupedAgents = groupWorkstationHistoryItemsByDate(workflowRunHistory);
  const handleMobileClick = (route: string) => {
    void onItemClick(route);
  };
  return <HistoryContainer>
      <SelectContainer>
        <Select onChange={setWorkflowRunTypeFilter} options={[...Object.values(ChatListWorkflowRunTypeFilter).map(type => ({
        label: CHAT_WORKFLOW_RUN_TYPE_TO_LABEL[type],
        value: type
      }))]} placeholderLabel="Filter by agent type" prefix="Type: " value={workflowRunTypeFilter} />
      </SelectContainer>
      {WORKSTATION_HISTORY_DATE_GROUPS.map(({
      key,
      title
    }) => {
      const chats = groupedAgents[key];
      return chats && chats.length > 0 ? <GroupContainer key={key}>
            <GroupTitle title={title} />
            {chats.map(chat => {
          const route = getWorkstationRoute({
            chatId: chat.id,
            workflowId: chat.workflowId || undefined
          });
          return <HistoryItem key={chat.id}>
                  <AgentBadge>
                    <Typography font={TypographyFont.HEADING} size={TypographySize.X_SMALL} weight={TypographyWeight.MEDIUM}>
                      {WORKFLOW_TYPE_TO_ABBREVIATION[chat.workflowRunType]}
                    </Typography>
                  </AgentBadge>
                  <Typography color={urlChatId === chat.id ? TextIconColor.PRIMARY : TextIconColor.SECONDARY} href={isMobileOnly ? '' : route} onClick={() => {
              if (isMobileOnly) {
                handleMobileClick(route);
              }
            }}>
                    {chat.title}
                  </Typography>
                </HistoryItem>;
        })}
          </GroupContainer> : null;
    })}
      {hasNextPage && <LoadMoreButtonContainer>
          <Button label={isLoading ? 'Loading...' : 'Load more'} onClick={() => {
        void fetchNextPage();
      }} type={ButtonType.SOLID_COLOR} />
        </LoadMoreButtonContainer>}
    </HistoryContainer>;
};
export default WorkflowRunHistory;