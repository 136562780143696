import _styled from "styled-components";
import { useState } from 'react';
import { TabNavigator, TabOption, TextIconColor, Typography, TypographySize } from '../../core';
import { ChatMessageCodeExecution } from '../Chat.types';
import { TypographyWrap } from '~/components/core/Typography';
import { Markdown } from '~/components/Markdown';
const ExecutionResultContainer = _styled.div({});
const TabViewContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.375rem"
});
const ExceptionContainer = _styled.div({
  "gap": "1.5rem",
  "borderRadius": "0.375rem",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(248 113 113 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(254 202 202 / var(--tw-bg-opacity))",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "1.5rem",
  "paddingBottom": "1.5rem"
});
const tabViewContentHeight = '300';
const SyntaxHighlighterWrapper = _styled.div({
  "flexGrow": "1",
  "overflow": "auto"
});
const MultilineResultText = _styled.code({
  "display": "block",
  "overflow": "auto",
  "borderRadius": "0.25rem",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(9 9 9 / var(--tw-bg-opacity))",
  "padding": "0.25rem"
});
const TabContentWrapper = _styled.div({
  "display": "flex",
  "height": "300px",
  "flexDirection": "column"
});

// TODO: Handle streaming via Pusher
interface CodeExecutionResultProps {
  chatMessageCodeExecution: NonNullable<ChatMessageCodeExecution>;
}
enum CodeExecutionResultTabs {
  CODE = 'CODE',
  STDERR = 'STDERR',
  STDOUT = 'STDOUT',
}
const codeExecutionResultTabOptions: TabOption<CodeExecutionResultTabs>[] = [{
  label: 'Code',
  value: CodeExecutionResultTabs.CODE
}, {
  label: 'Output',
  value: CodeExecutionResultTabs.STDOUT
}, {
  label: 'Error',
  value: CodeExecutionResultTabs.STDERR
}];
const CodeExecutionResultChatMessage: React.FC<CodeExecutionResultProps> = ({
  chatMessageCodeExecution
}) => {
  // State
  const [activeTab, setActiveTab] = useState<CodeExecutionResultTabs | null>(null);
  const tabToDisplay: CodeExecutionResultTabs = activeTab || (chatMessageCodeExecution.stdout !== '' ? CodeExecutionResultTabs.STDOUT : CodeExecutionResultTabs.CODE);

  // TODO: Support other languages besides python
  const renderTabContent = () => {
    switch (tabToDisplay) {
      case CodeExecutionResultTabs.CODE:
        return <SyntaxHighlighterWrapper>
            <Markdown>
              {/* TODO: make this runnable, and language dynamic */}
              {/* TODO: Make the syntax highlighter header fixed */}
              {`\`\`\`python\n${chatMessageCodeExecution.code}\n\`\`\``}
            </Markdown>
          </SyntaxHighlighterWrapper>;
      case CodeExecutionResultTabs.STDOUT:
        return chatMessageCodeExecution.stdout !== '' ? <MultilineResultText>
            <Typography size={TypographySize.X_SMALL} wrap={TypographyWrap.PRE_WRAP}>
              {chatMessageCodeExecution.stdout}
            </Typography>
          </MultilineResultText> : null;
      case CodeExecutionResultTabs.STDERR:
        return chatMessageCodeExecution.stderr !== '' ? <MultilineResultText>
            <Typography color={TextIconColor.DESTRUCTIVE} size={TypographySize.X_SMALL} wrap={TypographyWrap.PRE_WRAP}>
              {chatMessageCodeExecution.stderr}
            </Typography>
          </MultilineResultText> : null;
      default:
        return null;
    }
  };
  return <ExecutionResultContainer>
      <TabViewContainer>
        <TabNavigator<CodeExecutionResultTabs> activeTab={tabToDisplay} onClick={setActiveTab} tabOptions={codeExecutionResultTabOptions} />
        {/* TODO: determine how we want to constrain height */}
        <TabContentWrapper>{renderTabContent()}</TabContentWrapper>

        {chatMessageCodeExecution.exception && <ExceptionContainer>
            <Typography size={TypographySize.X_SMALL}>
              {/* TODO: Make sure this is safe when we have a final type */}
              {/* TODO: Cap the height of the exception container */}
              {JSON.stringify(chatMessageCodeExecution.exception)}
            </Typography>
          </ExceptionContainer>}
      </TabViewContainer>
    </ExecutionResultContainer>;
};
export default CodeExecutionResultChatMessage;