import _styled from "styled-components";
import { forwardRef } from 'react';
import { ResponseChatMessage } from '../Chat.types';
import { getErrorMessageText, getErrorMessageTitle, getMessageErrorInfo } from '../ChatMessage.utils';
import useSmoothStreaming from '../ResponseChatMessage/useSmoothStreaming';
import { ButtonType, Size, TextIconColor, Typography, TypographyCasing, TypographyFont, TypographySize, TypographyWeight } from '~/components/core';
import { Markdown } from '~/components/Markdown';
import { ContentReference, CopyButton } from '~/components/shared';
import { useAppSelector } from '~/hooks';
const BatchResponseMessageContainer = _styled.div<{
  $isError?: boolean;
  $isHandledKindoError?: boolean;
}>(({
  $isError,
  $isHandledKindoError
}) => [{
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "23px",
  "borderRadius": "0 3px 3px 3px",
  "borderWidth": "1px",
  "borderBottomWidth": "0px",
  "borderTopWidth": "0px",
  "--tw-border-opacity": "1",
  "borderLeftColor": "rgb(30 33 65 / var(--tw-border-opacity))",
  "borderRightColor": "rgb(30 33 65 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(13 15 31 / var(--tw-bg-opacity))",
  "paddingLeft": "3rem",
  "paddingRight": "3rem",
  "paddingTop": "2.25rem",
  "paddingBottom": "2.25rem",
  "--tw-backdrop-blur": "blur(53.5px)",
  "WebkitBackdropFilter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)",
  "backdropFilter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)"
}, $isHandledKindoError && {
  "--tw-border-opacity": "1",
  "borderColor": "rgb(253 235 144 / var(--tw-border-opacity))"
}, $isError && {
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(255 51 108 / var(--tw-border-opacity))"
}]);
const ContentAndResponseContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "23px"
});
const ContentContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "alignItems": "center",
  "justifyContent": "space-between",
  "gap": "0.5rem",
  "borderRadius": "0.5rem",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(22 18 61 / var(--tw-bg-opacity))",
  "paddingLeft": "1.5rem",
  "paddingRight": "1.5rem",
  "paddingTop": "0.75rem",
  "paddingBottom": "0.75rem"
});
const ResponseContainer = _styled.div({
  "display": "flex",
  "flex": "3"
});
const ContentReferences = _styled.div({
  "display": "flex",
  "flex": "1 1 0%",
  "flexWrap": "wrap",
  "gap": "0.5rem"
});
const ActionButtonsAndMessageContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "23px"
});
const ActionButtons = _styled.div({
  "display": "flex",
  "width": "100%",
  "justifyContent": "space-between",
  "paddingRight": "1rem"
});
const HiddenButtons = _styled.div({
  "visibility": "hidden",
  "display": "flex",
  "alignItems": "center",
  "gap": "0.5rem",
  ".group\\/message:hover &": {
    "visibility": "visible"
  }
});
const ErrorMessageContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "23px"
});
interface BatchResponseMessageProps {
  chatMessage: ResponseChatMessage;
  fileNumber: number;
  totalBatchedResponses: number;
}
const BatchResponseMessage = forwardRef<HTMLDivElement, BatchResponseMessageProps>(({
  chatMessage,
  fileNumber,
  totalBatchedResponses
}, ref) => {
  const {
    isStreaming,
    message,
    referencedContent,
    state
  } = chatMessage;

  // Redux
  const {
    userId
  } = useAppSelector(({
    user
  }) => user);

  // Custom Hooks
  const {
    text: smoothStreamingText
  } = useSmoothStreaming({
    chatMessage
  });
  const {
    isError,
    isHandledKindoError,
    displayedMessage
  } = getMessageErrorInfo(message, state);
  const renderMessageContent = () => {
    if (isStreaming && smoothStreamingText) {
      return <Markdown chatMessageId={chatMessage.id} isStreaming>
          {smoothStreamingText}
        </Markdown>;
    }
    if (isError) {
      return <ErrorMessageContainer>
          <Typography color={isHandledKindoError ? TextIconColor.WARNING : TextIconColor.ERROR} font={TypographyFont.HEADING} size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD}>
            {getErrorMessageTitle(isHandledKindoError, message)}
          </Typography>
          <Typography color={TextIconColor.PRIMARY} size={TypographySize.MEDIUM}>
            {getErrorMessageText(isHandledKindoError, displayedMessage)}
          </Typography>
        </ErrorMessageContainer>;
    }
    return <Markdown chatMessageId={chatMessage.id} isStreaming={isStreaming}>
        {displayedMessage}
      </Markdown>;
  };
  return <BatchResponseMessageContainer $isError={isError} $isHandledKindoError={isHandledKindoError} className="group/message" ref={ref}>
      <ContentAndResponseContainer>
        <ContentContainer>
          {!!referencedContent?.length && <ContentReferences>
              {referencedContent.map(content => <ContentReference canDownload={content.creatorId === userId} contentId={content.contentId} fileName={content.fileName} key={content.referenceId} nameMaxWidth="max-w-[150px]" source={content.source} tooltip={content.sourceContent} />)}
            </ContentReferences>}
          <Typography casing={TypographyCasing.UPPERCASE} color={TextIconColor.PRIMARY_HIGHLIGHT} font={TypographyFont.HEADING} size={TypographySize.X_SMALL} weight={TypographyWeight.BOLD}>
            {`File ${fileNumber} of ${totalBatchedResponses}`}
          </Typography>
        </ContentContainer>
        <ResponseContainer>
          <ActionButtonsAndMessageContainer>
            {renderMessageContent()}
            {!isError && <ActionButtons>
                <HiddenButtons>
                  <CopyButton buttonType={ButtonType.SOLID_COLOR} size={Size.SMALL} text={message} />
                  {/* TODO: Regenerate message and regenerate entire batch */}
                </HiddenButtons>
              </ActionButtons>}
          </ActionButtonsAndMessageContainer>
        </ResponseContainer>
      </ContentAndResponseContainer>
    </BatchResponseMessageContainer>;
});
export default BatchResponseMessage;